import { graphql, useStaticQuery } from 'gatsby';
import classnames from 'classnames';
import uniq from 'lodash/uniq';
import React from 'react';

import { Tags as TagsComponent } from './TagsComponent';

const QUERY = graphql`
  query {
    allMarkdownRemark {
      nodes {
        frontmatter {
          title
          slug
          tags
        }
      }
    }
    allDevArticles {
      edges {
        node {
          article {
            title
            tags
            slug
          }
        }
      }
    }
  }
`;

function allTags(posts, drops) {
  const postsTags = posts.flatMap(post => post.tags);
  const dropsTags = drops.flatMap(drop => drop.frontmatter.tags);
  return uniq([...postsTags, ...dropsTags]);
}

const Tags = ({ home = false }) => {
  const data = useStaticQuery(QUERY);
  const drops = data.allMarkdownRemark.nodes;
  const posts = data.allDevArticles.edges.map(edge => edge.node.article);
  const className = classnames({ 'pt-10': !home });

  return (
    <div className={className}>
      <h2 className="text-2xl">{home ? 'tags' : 'Tags'}</h2>
      <TagsComponent
        tags={allTags(posts, drops).sort()}
        className="leading-10 text-sm"
        tagClass="p-2"
      />
    </div>
  );
};

export { Tags };
